import React from "react"
import Layout from "../components/layout"
import { css } from "@emotion/core"
import { rhythm } from "../utils/typography.js"
import mugShot from "./MugShot.jpg"
import { Helmet } from "react-helmet"
require("prismjs/themes/prism-coy.css")

export default function Home({ data }) {
  return (
    <div
      css={css`
        margin: 1 auto;
      `}
    >
      <title> Josh Burns - Software Quality Advocate - London </title>

      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="http://joshburnstech.uk" />
      </Helmet>

      <Layout>
        <div
          css={css`
            height: 100vh;
            max-width: 400px;
            margin-left: auto;
            margin-right: auto;
          `}
        >
          <img
            src={mugShot}
            alt="selfie"
            css={css`
              order: 4;
              width: 100%;
              max-width: 600px;
              height: auto;
              float: right;
              padding: ${rhythm(0.5)};
              max-height: 400px;
            `}
          />
          <div
            css={css`
              margin-top: ${rhythm(0.5)};
              order: 4;
              margin-left: 5%;
              margin-right: 5%;
            `}
          >
            Hi, I'm Josh. I'm an SDET - Software Developer in Test.
            <br />
            <br />
            I'm currently working for Dunelm - One of the UK's largest homeware
            retailers, and{" "}
            <a href="https://aws.amazon.com/solutions/case-studies/dunelm-case-study/">
              {" "}
              one of AWS' biggest customers worldwide.{" "}
            </a>
            I'm working each day on some really interesting problems, some of
            them unique to working at scale. I work on integrating quality
            practices throughout the agile workflow, through tooling, analysis,
            automation and initiatives to ensure quality is a whole-team effort.
            <br />
          </div>
        </div>
      </Layout>
    </div>
  )
}

// export const query = graphql`
//   query {
//     allFile {
//       edges {
//         node {
//           id
//           base
//         }
//       }
//     }
//   }
// `
